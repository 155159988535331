<template>
  <fragment>
    <tr
      :key="`${item.rowId}`"
      v-show="!hidden"
      :class="{ parrent: item.children.length > 0, 'top-level': level == 0 }"
    >
      <td :style="`padding-left: ${10 * level}px`">
        <div
          v-if="item.children.length > 0"
          @click="collapsed = !collapsed"
          class="collapse-btn"
        >
          <feather-icon v-if="collapsed" icon="PlusIcon" />
          <feather-icon v-else icon="MinusIcon" />
        </div>
        <span>{{ item.label }}</span>
      </td>

      <td class="total-td" v-for="(valueOption, index) in selectedValuesOptions" :key="index">
        {{item.rowTotals.find(t => t.label == valueOption.label).displayValue}}
      </td>

      <template v-for="(column, index) in columns">
        <td
          v-for="(rowItem, rowItemIndex) in item.rowColumnsData[index].values"
          :key="`${item.id}:${index}:${rowItem.label}`"
          :class="{
            'col-seperator':
              rowItemIndex + 1 == item.rowColumnsData[index].values.length,
          }"
        >
          <data-source-report-modal
            v-if="rowItem.value > 0"
            :ref="`${item.id}-level-${level}-report-modal`"
          />
          <span
            :class="{ 'row-value': rowItem.value > 0 }"
            >{{ rowItem.displayValue }}</span
          >
        </td>
      </template>
    </tr>
    <data-table-row-level
      :hidden="childShouldBeHidden"
      :data="item.children"
      :columns="columns"
      :key="`${item.rowId}-child-${level}`"
      :level="level + 1"
      :selectedValuesOptions="selectedValuesOptions"
    />
  </fragment>
</template>

<script>
import { BButton } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import DataTableRowLevel from "./DataTableRowLevel.vue";
import DataSourceReportModal from "./DataSourceReportModal.vue";
export default {
  components: {
    FeatherIcon,
    BButton,
    DataTableRowLevel,
    DataSourceReportModal,
  },
  props: ["data", "columns", "hidden", "level", "item", "selectedValuesOptions"],
  name: "data-table-row",
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    childShouldBeHidden() {
      return this.hidden == true ? true : this.collapsed;
    },
  },
  methods: {
    openReportModal(modalRef) {
      this.$refs[modalRef][0].open();
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  td {
      text-align: right;
    &:first-child {
      width: 300px;
      display: flex;
      align-items: right;
      background: #fff;
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      box-shadow: 4px 0 4px rgba(0, 0, 0, 0.05);
      border-right: 2px solid #e4e4e4;

      span {
        padding: 0 5px;
      }
    }
    &:nth-child(2) {
      padding-left: 10px;
    }
    &:not(:first-child) {
      padding: 0 10px;
      .row-value {
        /*
        &:hover {
          cursor: help;
          color: #407e3d;
        }
        */
      }
    }
    &:not(:last-child) {
      &.col-seperator {
        border-right: 2px solid #e4e4e4;
      }
    }
    &.total-td {
      background-color: #e9e9ec;
    }
  }
  &.parrent,
  &.top-level {
    td {
      font-weight: bold;
    }
  }
  &.top-level {
    td {
      border-bottom: 2px solid #e4e4e4;
    }
  }
}

.collapse-btn {
  background: #f3f2f7;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e4e4;
  font-weight: bold;
  color: #6e6b7b;
  margin: 0px 0px 0px 5px;
  &:hover {
    cursor: pointer;
  }
}
</style>